import { UpdateModerationItemStatusRequestData, ENDPOINTS } from 'api';
import axios from 'axios';

export const fetchUpdateGalleryPhotoStatus = async ({
  photoId,
  croppedImage,
  status,
  declineReason
}: UpdateModerationItemStatusRequestData & {
  photoId: string;
  croppedImage?: File;
}) => {
  const formData = new FormData();

  formData.append('status', status);

  if (declineReason) formData.append('declineReason', declineReason);

  if (croppedImage) formData.append('image', croppedImage);

  await axios.patch(
    `${ENDPOINTS.MODERATION_PHOTO}/${photoId}/status`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};
