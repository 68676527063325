import { ModalType } from 'enum';

import {
  AddLinkedAccount,
  AddTransaction,
  AdminReportDetails,
  CancelCustomerSubscription,
  CreateAccount,
  CreateComment,
  CreateNewAdmin,
  CreateNewSubscription,
  CreateNewTransaction,
  CreateParameterValue,
  CreateReport,
  CreateStaticPage,
  CreateTicket,
  CustomerContentList,
  CustomerFullList,
  CustomerReportDetails,
  DeactivateCustomerAccount,
  DeclineItem,
  DeleteAdmin,
  DeleteAdminComment,
  DeleteArticle,
  DeleteCustomerAccount,
  DeleteCustomerComment,
  DeleteDomainConfig,
  DeletePopup,
  DeleteStaticPage,
  DeleteSubscription,
  DownloadCSV,
  EditArticle,
  EditDomainConfig,
  EditLabels,
  EditPaymentMethodCountries,
  EditPhoto,
  EditPopup,
  EditStaticPage,
  EditTransaction,
  EmailHistory,
  Inactivity,
  ModerationUserProfile,
  NicknameHistory,
  QuickReplyCustomer,
  ReactivateCustomerAccount,
  ReplyCustomer,
  ResetAllAdminPasswords,
  ResetCustomerPassword,
  RestrictionViewCustomers,
  SendEmail,
  SendSMS,
  StopRenewSubscription,
  TicketConversations,
  UnlinkLinkedAccount,
  UpdateComment,
  UpdateCustomerGalleryImageStatus,
  UpdateCustomerMessageImageStatus,
  UpdateCustomerStoryStatus,
  ViewConversations,
  DeleteCrmUsers,
  BlockCrmUsers,
  ViewPopupMetrics,
  RequiredCustomersChatAccessRequest
} from './Modals';
import { EditCountries, EditJSON, EditText } from './Modals/edit';

export const MODALS: Record<ModalType, () => JSX.Element> = {
  [ModalType.DeleteAdminUser]: DeleteAdmin,
  [ModalType.CreateAdminUser]: CreateNewAdmin,
  [ModalType.Inactivity]: Inactivity,
  [ModalType.ResetAllAdminPasswords]: ResetAllAdminPasswords,
  [ModalType.EditLabels]: EditLabels,
  [ModalType.ResetCustomerPassword]: ResetCustomerPassword,
  [ModalType.CreateCustomerAccount]: CreateAccount,
  [ModalType.NicknameHistory]: NicknameHistory,
  [ModalType.EmailHistory]: EmailHistory,
  [ModalType.CreateComment]: CreateComment,
  [ModalType.EditAdminComment]: UpdateComment,
  [ModalType.DeleteAdminComment]: DeleteAdminComment,
  [ModalType.DeleteCustomerComment]: DeleteCustomerComment,
  [ModalType.AddLinkedAccount]: AddLinkedAccount,
  [ModalType.UnlinkLinkedAccount]: UnlinkLinkedAccount,
  [ModalType.DownloadCustomerCSV]: DownloadCSV,
  [ModalType.AddCustomerTransaction]: AddTransaction,
  [ModalType.DeactivateCustomerAccount]: DeactivateCustomerAccount,
  [ModalType.DeleteCustomerAccount]: DeleteCustomerAccount,
  [ModalType.ReactivateCustomerAccount]: ReactivateCustomerAccount,
  [ModalType.StopRenewSubscription]: StopRenewSubscription,
  [ModalType.CancelCustomerSubscription]: CancelCustomerSubscription,
  [ModalType.UpdateCustomerGalleryImageStatus]:
    UpdateCustomerGalleryImageStatus,
  [ModalType.UpdateCustomerMessageImageStatus]:
    UpdateCustomerMessageImageStatus,
  [ModalType.UpdateCustomerStoryStatus]: UpdateCustomerStoryStatus,
  [ModalType.DeclineItem]: DeclineItem,
  [ModalType.EditPhoto]: EditPhoto,
  [ModalType.UserProfile]: ModerationUserProfile,
  [ModalType.CustomerFullList]: CustomerFullList,
  [ModalType.ReplyCustomer]: ReplyCustomer,
  [ModalType.QuickReplyCustomer]: QuickReplyCustomer,
  [ModalType.TicketConversation]: TicketConversations,
  [ModalType.CreateTicket]: CreateTicket,
  [ModalType.EditPaymentMethodCountries]: EditPaymentMethodCountries,
  [ModalType.CreateNewSubscription]: CreateNewSubscription,
  [ModalType.CreateNewTransaction]: CreateNewTransaction,
  [ModalType.UpdateTransaction]: EditTransaction,
  [ModalType.CustomerReportDetails]: CustomerReportDetails,
  [ModalType.AdminReportDetails]: AdminReportDetails,
  [ModalType.RestrictionViewCustomers]: RestrictionViewCustomers,
  [ModalType.RequiredCustomersChatAccessRequest]:
    RequiredCustomersChatAccessRequest,
  [ModalType.ViewConversations]: ViewConversations,
  [ModalType.DeleteArticle]: DeleteArticle,
  [ModalType.EditArticle]: EditArticle,
  [ModalType.CreateReport]: CreateReport,
  [ModalType.CustomerContentList]: CustomerContentList,
  [ModalType.DeleteSubscription]: DeleteSubscription,
  [ModalType.SendEmail]: SendEmail,
  [ModalType.SendSMS]: SendSMS,
  [ModalType.EditJSON]: EditJSON,
  [ModalType.EditText]: EditText,
  [ModalType.EditCountries]: EditCountries,
  [ModalType.CreateParameterValue]: CreateParameterValue,
  [ModalType.CreateStaticPage]: CreateStaticPage,
  [ModalType.DeleteStaticPage]: DeleteStaticPage,
  [ModalType.EditStaticPage]: EditStaticPage,
  [ModalType.DeletePopup]: DeletePopup,
  [ModalType.EditPopup]: EditPopup,
  [ModalType.ViewPopupMetrics]: ViewPopupMetrics,
  [ModalType.DeleteDomainConfig]: DeleteDomainConfig,
  [ModalType.EditDomainConfig]: EditDomainConfig,
  [ModalType.DeleteCrmUsers]: DeleteCrmUsers,
  [ModalType.BlockCrmUsers]: BlockCrmUsers
};
