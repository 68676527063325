import { Grid, GridItem } from '@chakra-ui/react';
import {
  GalleryImageResponse,
  MessageImageResponse,
  StoryResponse
} from 'api/Content/Content.types';
import { AssetType } from 'enum/api';
import {
  ICustomerGalleryImages,
  ICustomerMessageImages,
  ICustomerStoryList
} from 'types/customer';

import { AssetActionItems, AssetCard } from './AssetCard';

type Props = Pick<
  Parameters<typeof AssetActionItems>[0],
  'onDeclineAsset' | 'onDeleteAsset' | 'onRestoreAsset'
> & {
  data:
    | ICustomerStoryList[]
    | ICustomerMessageImages[]
    | ICustomerGalleryImages[]
    | GalleryImageResponse[]
    | MessageImageResponse[]
    | StoryResponse[];
};

export const AssetsContainer = ({
  data,
  onDeleteAsset,
  onRestoreAsset,
  onDeclineAsset
}: Props): JSX.Element => (
  <Grid
    gap={4}
    gridTemplateColumns="repeat(auto-fill, minmax(16rem, 1fr))"
    height="100%"
  >
    {data.map((item) => {
      const isStory = 'assets' in item;
      const assets = isStory
        ? item.assets
        : [
            {
              ...item,
              assetUrl: item.imageUrl,
              type: AssetType.Image
            }
          ];

      return (
        <GridItem key={item.id}>
          <AssetCard
            assets={assets}
            uploadedAt={new Date(item.uploadedAt)}
            statusAssignedAt={new Date(item.statusedAt)}
            status={item.status}
            assetActions={
              <AssetActionItems
                assetId={item.id}
                assetStatus={item.status}
                onDeleteAsset={onDeleteAsset}
                onDeclineAsset={onDeclineAsset}
                onRestoreAsset={onRestoreAsset}
              />
            }
            userId={
              (
                item as
                  | GalleryImageResponse
                  | MessageImageResponse
                  | StoryResponse
              ).userId
            }
          />
        </GridItem>
      );
    })}
  </Grid>
);
