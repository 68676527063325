export enum DropdownType {
  Select = 'select',
  Range = 'range',
  Button = 'button',
  Custom = 'custom',
  Tag = 'tag'
}

export enum PageName {
  Login = 'login',
  Examples = 'examples',
  AdminPanel = 'admin_panel',
  AdminManagement = 'admin_management',
  Customers = 'customers',
  Settings = 'settings',
  Moderation = 'moderation',
  LogActivity = 'log_activity',
  PopupsManagement = 'popups_management',
  ContactUs = 'contact_us',
  Reports = 'reports',
  PaymentsManagement = 'payments_management',
  BlogManagement = 'blog_management',
  Content = 'content',
  TablesManagement = 'tables_management',
  CRM = 'crm',
  StaticPages = 'static_pages',
  DomainsConfig = 'domains_config'
}

export enum AdminDetailsPage {
  Edit = 'edit',
  LoginSession = 'loginSession'
}

export enum CustomerPage {
  Dashboard = 'dashboard',
  PersonalDetails = 'personalDetails',
  AboutMe = 'aboutMe',
  AccountSettings = 'accountSettings',
  NotificationSettings = 'notificationSettings',
  AdminInteraction = 'adminInteraction',
  CustomerInteractions = 'customerInteractions',
  CustomerReports = 'customerReports',
  CustomerContent = 'customerContent',
  CustomerPayments = 'customerPayments',
  CustomerBadWords = 'customerBadWords'
}

export enum CustomerSection {
  AdminComments = 'adminComments',
  LinkedAccounts = 'linkedAccounts',
  LoginHistory = 'loginHistory',
  MessageImages = 'messageImages',
  GalleryImages = 'galleryImages',
  Stories = 'stories',
  CommentsWrittenToUser = 'commentsWrittenToUser',
  CommentsWrittenByUser = 'commentsWrittenByUser',
  CustomerNotesToUser = 'customerNotesToUser',
  CustomerNotesByUser = 'customerNotesByUser',
  FavouritesAddedByCustomer = 'favouritesAddedByCustomer',
  PartnersAddedCustomerToFavourites = 'partnersAddedCustomerToFavourites',
  LikedByCustomer = 'likedByCustomer',
  PartnersLikedCustomer = 'partnersLikedCustomer',
  BlackListByCustomer = 'blackListByCustomer',
  CustomersAddedCustomerToBlacklist = 'customersAddedCustomerToBlacklist',
  CustomerMessages = 'customerMessages',
  CustomerReports = 'customerReports',
  ReportsAgainstCustomer = 'reportsAgainstCustomer',
  TicketHistory = 'ticketHistory',
  AdminReports = 'adminReports'
}

export enum ModerationPage {
  GalleryPhoto = 'galleryPhoto',
  Comment = 'comment',
  Motto = 'motto',
  SomethingAboutMe = 'somethingAboutMe',
  Declined = 'declined'
}

export enum ModerationSection {
  GalleryPhoto = 'galleryPhoto',
  Comment = 'comment',
  Motto = 'motto',
  SomethingAboutMe = 'somethingAboutMe'
}

export enum PaymentPage {
  PaymentMethod = 'paymentMethod',
  Subscription = 'subscription',
  Transaction = 'transaction'
}

export enum ReportPage {
  Customer = 'Customer',
  Admin = 'Admin'
}

export enum ReportSection {
  ProfileReports = 'ProfileReports',
  GalleryReports = 'GalleryReports',
  StoryReports = 'StoryReports'
}

export enum TableManagementPage {
  ParameterOptions = 'ParameterOptions',
  ParameterValues = 'ParameterValues'
}

export enum BlogSection {
  Blog = 'Blog',
  News = 'News'
}

export enum ContentSection {
  MessageImages = 'MessageImages',
  GalleryImages = 'GalleryImages',
  Stories = 'Stories'
}

export enum Direction {
  Left = 'left',
  Right = 'right',
  UP = 'up',
  Down = 'down'
}

export enum ScrollDirection {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export enum UserEvent {
  Enter = 'Enter'
}

export enum InputElement {
  Input = 'input',
  NumberInput = 'number_input',
  Password = 'password',
  TextArea = 'text_area',
  MdEditor = 'md_editor',
  Checkbox = 'checkbox',
  Radio = 'radio',
  CheckboxGroup = 'checkbox_group',
  GroupRadio = 'group_radio',
  Select = 'select',
  LocationSelect = 'location',
  MultiSelect = 'multi_select',
  Calendar = 'calendar',
  Range = 'range',
  UploadFile = 'upload_file',
  Toggle = 'Toggle',
  DatePicker = 'date_picker',
  DateRangePicker = 'date_range_picker',
  DateTimeRangePicker = 'date_time_range_picker',
  FrequencyScheduler = 'frequency_scheduler'
}

export enum FormField {
  Email = 'email',
  Password = 'password',
  PasswordConfirmation = 'passwordConfirmation',
  Id = 'id',
  User = 'user',
  UserName = 'userName',
  UserType = 'userType',
  Status = 'status',
  Country = 'country',
  Countries = 'countries',
  State = 'state',
  City = 'city',
  Latitude = 'latitude',
  Longitude = 'longitude',
  Location = 'location',
  RegisteredCountry = 'registeredCountry',
  PhoneNumber = 'phoneNumber',
  Gender = 'gender',
  Genders = 'genders',
  OtherGenders = 'otherGenders',
  LookingForGender = 'lookingForGender',
  Ages = 'ages',
  BirthDate = 'birthDate',
  Label = 'label',
  CustomerRole = 'customerRole',
  CustomerType = 'customerType',
  CustomerTypes = 'customerTypes',
  OtherCustomerType = 'otherCustomerType',
  OtherCustomerTypes = 'otherCustomerTypes',
  RelationshipSettings = 'relationshipSettings',
  AvailabilityType = 'availabilityType',
  BusinessType = 'businessType',
  RelationshipTarget = 'relationshipTarget',
  IncomeType = 'incomeType',
  Motto = 'motto',
  Language = 'language',
  Interest = 'interest',
  AboutMe = 'aboutMe',
  NotificationPreferences = 'notificationPreferences',
  Height = 'height',
  DrinkingType = 'drinkingType',
  EyeColor = 'eyeColor',
  SmokingType = 'smokingType',
  BodyType = 'bodyType',
  FamilyStatus = 'familyStatus',
  HairColor = 'hairColor',
  ChildType = 'childType',
  EthnicityType = 'ethnicityType',
  ReligionType = 'religionType',
  BadWords = 'badWords',
  BadWordsMessageWarning = 'badWordsMessageWarning',
  BadWordsMessageBlocking = 'badWordsMessageBlocking',
  BadWordsProfileWarning = 'badWordsProfileWarning',
  Comment = 'comment',
  SourcePhoneNumber = 'sourcePhoneNumber',
  SourceNickname = 'sourceNickname',
  SourceEmail = 'sourceEmail',
  SourceUserId = 'sourceUserId',
  Files = 'files',
  Custom = 'custom',
  SubscriptionProgramType = 'subscriptionProgramType',
  SubscriptionTypeName = 'subscriptionTypeName',
  SubscriptionDisplayName = 'subscriptionDisplayName',
  SubscriptionCurrencySign = 'subscriptionCurrencySign',
  SumPaid = 'sumPaid',
  Currency = 'currency',
  Reason = 'reason',
  SupportSourceType = 'supportSourceType',
  Duration = 'duration',
  Period = 'period',
  Title = 'title',
  Code = 'code',
  TabTitle = 'tabTitle',
  SubTitle = 'subTitle',
  MetaDescription = 'metaDescription',
  Message = 'message',
  Actions = 'actions',
  ThankYou = 'thankYou',
  Banner = 'banner',
  ProgramType = 'programType',
  ProgramTypes = 'programTypes',
  OtherProgramTypes = 'otherProgramTypes',
  AutoClosePopup = 'autoClosePopup',
  Seconds = 'seconds',
  PopupPages = 'popupPages',
  KeyWords = 'keyWords',
  ArticleType = 'articleType',
  MessageAmount = 'messageAmount',
  ConversationAmount = 'conversationAmount',
  CustomersAmount = 'customersAmount',
  FreeLikesAmount = 'freeLikesCount',
  PremiumLikesAmountPerDay = 'premiumLikesPerDayCount',
  EverybodyCanSeeEverybody = 'isEverybodyCanSeeEverybody',
  EverybodyCanMessageToEverybody = 'isEverybodyCanMessageToEverybody',
  AppliedForFreeProgramType = 'appliedForFreeProgramType',
  Domains = 'domains',
  Domain = 'domain',
  SisterCountries = 'sisterCountries',
  ShowOnHomeScreen = 'showOnHomeScreen',
  Order = 'order',
  Images = 'images',
  PreviewImages = 'previewImages',
  Suspicious = 'suspicious',
  ShowOnWebsite = 'showOnWebsite',
  GeneralArticle = 'generalArticle',
  ReportType = 'reportType',
  LengthUnit = 'lengthUnit',
  RegistrationDateTime = 'registrationDateTime',
  FinishRegistrationDateTime = 'finishRegistrationDateTime',
  PaymentPackageType = 'paymentPackageType',
  MessagesSent = 'messagesSent',
  MessagesReceived = 'messagesReceived',
  LikesReceived = 'likesReceived',
  LikesGave = 'likesGave',
  FavouritesReceived = 'favouritesReceived',
  FavouritesGave = 'favouritesGave',
  EmailTemplateId = 'emailTemplateId',
  EmailTemplatePayload = 'emailTemplatePayload',
  SmsText = 'smsText',
  SubscriptionShowTotal = 'subscriptionShowTotal',
  SubscriptionShowSeparately = 'subscriptionShowSeparately',
  SubscriptionIsRenewing = 'subscriptionIsRenewing',
  SubscriptionSplitBy = 'subscriptionSplitBy',
  IsAdminUser = 'isAdminUser',
  SubscriptionOrder = 'subscriptionOrder',
  SubscriptionUserType = 'customerType',
  SubscriptionGender = 'gender',
  Text = 'text',
  Json = 'json',
  ParameterType = 'parameterType',
  ParameterTitle = 'parameterTitle',
  Meta = 'meta',
  Value = 'value',
  IsReplyFromCustomer = 'isReplyFromCustomer',
  MenuTitle = 'menuTitle',
  FaqItems = 'faqItems',
  Question = 'question',
  Answer = 'answer',
  IsFaqPage = 'isFaqPage',
  EnableMessagesRange = 'EnableMessagesRange',
  MessagesRangeMin = 'MessagesRangeMin',
  MessagesRangeMax = 'MessagesRangeMax',
  EnableSeenStoriesRange = 'EnableSeenStoriesRange',
  SeenStoriesRangeMin = 'SeenStoriesRangeMin',
  SeenStoriesRangeMax = 'SeenStoriesRangeMax',
  ImageLink = 'ImageLink',
  ActionBtnOneTitle = 'ActionBtnOneTitle',
  ActionBtnOneLink = 'ActionBtnOneLink',
  ActionBtnOneIsColored = 'ActionBtnOneIsColored',
  ActionBtnTwoTitle = 'ActionBtnTwoTitle',
  ActionBtnTwoLink = 'ActionBtnTwoLink',
  ActionBtnTwoIsColored = 'ActionBtnTwoIsColored',
  FrequencyScheduler = 'frequencyScheduler',
  FrequencyToggle = 'frequencyToggle',
  // domains configs
  SubDir = 'SubDir',
  WelcomePageAlias = 'welcomePageAlias',
  GoogleTagMKey = 'googleTagMKey',
  GoogleTagGKey = 'googleTagGKey',
  GoogleTagAwKey = 'googleTagAwKey',
  AdditionalFields = 'additionalFields',
  Assets = 'assets',
  AvailableTranslations = 'availableTranslations',
  TranslationEnDisplayName = 'translationEnDisplayName',
  TranslationEnFileName = 'translationEnFileName',
  TranslationRuDisplayName = 'translationRuDisplayName',
  TranslationRuFileName = 'translationRuFileName',
  TranslationTrDisplayName = 'translationTrDisplayName',
  TranslationTrFileName = 'translationTrFileName',
  TranslationHeDisplayName = 'translationHeDisplayName',
  TranslationHeFileName = 'translationHeFileName',
  TranslationDeDisplayName = 'translationDeDisplayName',
  TranslationDeFileName = 'translationDeFileName',
  TranslationFrDisplayName = 'translationFrDisplayName',
  TranslationFrFileName = 'translationFrFileName',
  TranslationEsDisplayName = 'translationEsDisplayName',
  TranslationEsFileName = 'translationEsFileName',
  TranslationItDisplayName = 'translationItDisplayName',
  TranslationItFileName = 'translationItFileName',
  TranslationElDisplayName = 'translationElDisplayName',
  TranslationElFileName = 'translationElFileName'
}

export enum ModalType {
  DeleteAdminUser = 'deleteAdminUser',
  CreateAdminUser = 'createAdminUser',
  ResetAllAdminPasswords = 'resetAllAdminPasswords',
  Inactivity = 'inactivity',
  // Customer
  EditLabels = 'editLabels',
  ResetCustomerPassword = 'resetCustomerPassword',
  CreateCustomerAccount = 'createCustomerAccount',
  NicknameHistory = 'nicknameHistory',
  EmailHistory = 'emailHistory',
  CreateComment = 'createComment',
  EditAdminComment = 'editAdminComment',
  DeleteAdminComment = 'deleteAdminComment',
  DeleteCustomerComment = 'deleteCustomerComment',
  AddLinkedAccount = 'addLinkedAccount',
  UnlinkLinkedAccount = 'unlinkLinkedAccount',
  DownloadCustomerCSV = 'downloadCustomerCSV',
  AddCustomerTransaction = 'addCustomerTransaction',
  DeactivateCustomerAccount = 'deactivateCustomerAccount',
  ReactivateCustomerAccount = 'reactivateCustomerAccount',
  DeleteCustomerAccount = 'deleteCustomerAccount',
  UpdateCustomerGalleryImageStatus = 'updateCustomerGalleryImageStatus',
  UpdateCustomerMessageImageStatus = 'updateCustomerMessageImageStatus',
  UpdateCustomerStoryStatus = 'updateCustomerStoryStatus',
  ViewConversations = 'viewConversations',
  CustomerFullList = 'customerFullList',
  CustomerContentList = 'customerContentList',

  // Moderation
  DeclineItem = 'declineItem',
  UserProfile = 'userProfile',
  EditPhoto = 'editPhoto',

  // Contact Us
  TicketConversation = 'TicketConversation',
  ReplyCustomer = 'replyCustomer',
  QuickReplyCustomer = 'quickReplyCustomer',
  CreateTicket = 'createTicket',

  // Payment Management
  EditPaymentMethodCountries = 'editPaymentMethodCountries',
  CreateNewSubscription = 'createNewSubscription',
  CreateNewTransaction = 'createNewTransaction',
  UpdateTransaction = 'updateTransaction',
  StopRenewSubscription = 'stopRenewSubscription',
  CancelCustomerSubscription = 'cancelCustomerSubscription',
  DeleteSubscription = 'deleteSubscription',

  // Reports
  CustomerReportDetails = 'customerReportDetails',
  AdminReportDetails = 'adminReportDetails',
  CreateReport = 'createReport',

  // General Settings
  RestrictionViewCustomers = 'restrictionViewCustomers',
  RequiredCustomersChatAccessRequest = 'requiredCustomersChatAccessRequest',

  //Blog Management
  DeleteArticle = 'deleteArticle',
  EditArticle = 'editArticle',

  //CRM
  SendEmail = 'sendEmail',
  SendSMS = 'sendSMS',
  DeleteCrmUsers = 'deleteCrmUsers',
  BlockCrmUsers = 'blockCrmUsers',

  //Edit content
  EditCountries = 'editCountries',
  EditJSON = 'editJSON',
  EditText = 'editText',

  //Popups Management
  DeletePopup = 'deletePopup',
  EditPopup = 'editPopup',
  ViewPopupMetrics = 'viewPopupMetrics',

  //Domain Config
  DeleteDomainConfig = 'deleteDomainConfig',
  EditDomainConfig = 'editDomainConfig',

  //Tables management
  CreateParameterValue = 'createParameterValue',

  //Static pages
  CreateStaticPage = 'createStaticPage',
  EditStaticPage = 'editStaticPage',
  DeleteStaticPage = 'deleteStaticPage'
}

export enum OnlineStatus {
  Online = 'Online',
  Offline = 'Offline'
}

export enum Range {
  Start = 'start',
  End = 'end'
}

export enum CustomerCSVFields {
  Nickname = 'nickname',
  ProgramType = 'programType',
  CustomerRole = 'customerRole',
  Email = 'email',
  CreatedAt = 'createdAt',
  Labels = 'labels',
  AccountStatus = 'accountStatus',
  PhoneNumber = 'phoneNumber'
}

export enum ModerationPostStatus {
  Approved = 'Approved',
  Declined = 'Declined',
  AutoDeclined = 'AutoDeclined',
  NotAccepted = 'NotAccepted'
}

export enum DeclineItemType {
  GalleryPhoto = 'galleryPhoto',
  Comment = 'comment',
  Motto = 'motto',
  AboutMe = 'aboutMe'
}

export enum Period {
  Today = 'today',
  Yesterday = 'yesterday',
  Week = 'week',
  CurrentMonth = 'current_month',
  LastMonth = 'last_month',
  Season = 'season',
  Year = 'year',
  AllTime = 'all'
}

export enum ReportAuthorType {
  Admin = 'Admin',
  User = 'User'
}

export enum BarChartLayout {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export enum StagingDomains {
  Nigeria = 'https://staging.sugardaddy.ng',
  Russia = 'https://front-dev.sugarinter.media'
}

export enum ProdDomains {
  Nigeria = 'https://sugardaddy.ng',
  Russia = 'https://sugardaddy.ru'
}
