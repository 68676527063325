import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { useModalActions } from 'hooks';
import mime from 'mime';
import { useState } from 'react';
import { Cropper, CropperRef } from 'react-advanced-cropper';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';
import 'react-advanced-cropper/dist/style.css';

export const EditPhoto = (): JSX.Element => {
  const [t] = useTranslation();
  const [blob, setBlob] = useState<Blob | null>(null);
  const { isOpen, onClose, meta } = useModalActions(ModalType.EditPhoto);

  const onSave = async () => {
    if (!blob) return;

    const buffer = await blob.arrayBuffer();
    const croppedImageFile = new File(
      [buffer],
      `cropped-image.${mime.getExtension(blob.type)}`,
      { type: blob.type }
    );

    meta?.setCroppedImage(croppedImageFile);
  };

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: t('actions.save'),
      colorScheme: 'primary',
      onClick: onSave
    }
  ];

  const onChange = (cropper: CropperRef) => {
    meta?.setFeedImageUrl(cropper.getCanvas()?.toDataURL() || null);
    cropper.getCanvas()?.toBlob((blob) => {
      setBlob(blob);
    });
  };

  return (
    <ModalContainer
      header={t('attribute.title.edit_photo')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      modalBodyProps={{
        overflow: 'unset',
        overflowX: 'unset',
        paddingY: 0
      }}
      size="5xl"
    >
      <Cropper
        src={meta?.photo.imageUrl}
        onChange={onChange}
        style={{ maxHeight: '600px', width: '100%' }}
        className="cropper"
      />
    </ModalContainer>
  );
};
